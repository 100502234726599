<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="100px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item prop="name" label="姓名">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="phone" label="手机">
                        <el-input v-model="formData.phone" type="textarea"></el-input>
                        <div class="form_tip">多个电话号码请以空格或回车隔开</div>
                    </el-form-item>
                    <el-form-item prop="code" label="邀请码">
                        <div>
                            <el-input
                                v-model="formData.code"
                                style="width: calc(100% - 100px)"
                                :disabled="formData.inviteNum > 0"
                            ></el-input>
                            <el-button style="margin-left: 15px" @click="random" :disabled="formData.inviteNum > 0"
                                >随机生成</el-button
                            >
                        </div>
                    </el-form-item>
                    <el-form-item prop="remark" label="备注">
                        <el-input v-model="formData.remark"></el-input>
                    </el-form-item>
                    <el-form-item prop="canInviteNum" label="可邀请人数">
                        <el-input-number v-model="formData.canInviteNum" type="number"></el-input-number>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InviteEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('invite/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }
                ],
                phone: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    },
                    // {
                    //     pattern: /^1[3-9]\d{9}$/,
                    //     message: '请输入正确的手机号',
                    //     trigger: 'blur'
                    // }
                ],
                code: [
                    {
                        required: false,
                        message: '请输入邀请码',
                        trigger: 'blur'
                    }
                ],
                canInviteNum: [
                    {
                        required: true,
                        message: '请输入可邀请人数',
                        trigger: 'blur'
                    },
                    {
                        pattern:/^[0-9]\d*$/,
                        message: '请输入正确的可邀请人数',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                // .post('/invite/save', data, { body: 'json' })
                .post(this.$route.query.id ? '/invite/save' : '/invite/batchSave', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/invite/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        random() {
            function makeid(length) {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }

            this.$set(this.formData, 'code', makeid(6));
        }
    }
};
</script>
<style lang="less" scoped></style>
