<template>
  <div class="edit-view">
    <page-title>
      <el-button @click="$router.go(-1)"
                 :disabled="saving">取消</el-button>
      <el-button @click="onDelete"
                 :disabled="saving"
                 type="danger"
                 v-if="formData.id"> 删除 </el-button>
      <el-button @click="onSave"
                 :loading="saving"
                 type="primary">保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form :model="formData"
                 :rules="rules"
                 ref="form"
                 label-width="120px"
                 label-position="right"
                 size="small"
                 style="max-width: 1000px">
          <el-form-item label="项目"
                        prop="projectId">
            <project-select v-model="formData.projectId">
            </project-select>
          </el-form-item>
          <el-form-item prop="name"
                        label="活动名称">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item prop="inviteStartdatetime"
                        label="活动开始时间">
            <el-date-picker v-model="formData.inviteStartdatetime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="inviteEnddatetime"
                        label="活动结束时间">
            <el-date-picker v-model="formData.inviteEnddatetime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>

          <el-form-item prop="poster"
                        label="活动海报">
            <single-upload v-model="formData.poster"
                           compress></single-upload>
            <!-- <div class="form_tip">1029x420</div> -->
          </el-form-item>
          <el-form-item prop="prl"
                        label="活动背景">
            <single-upload v-model="formData.prl"
                           accept="image/*"
                           compress></single-upload>
            <!-- <div class="form_tip">宽度1080px，长度2000px以内，可上传多张</div> -->
          </el-form-item>
          <el-form-item prop="content"
                        label="活动公告"
                        style="width: calc(100vw - 450px)">
            <rich-text v-model="formData.content"></rich-text>
          </el-form-item>
          <el-form-item prop="content"
                        label="被邀请获得"
                        style="width: calc(100vw - 450px)">
            <el-row :gutter="12">
              <el-col :span="6">
                <el-select v-model="formData.byInvitePrize.type"
                           clearable
                           filterable
                           :disabled="!canEdit"
                           @change="changeType(formData.byInvitePrize)"
                           placeholder="请选择被邀请获得奖品类型">
                  <el-option v-for="item in typeOptions"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="6">
                <collection-search :isEdit="isEdit"
                                   v-if="formData.byInvitePrize.type =='asset'"
                                   v-model="formData.byInvitePrize.inviteCollection"
                                   :projectId="formData.projectId"></collection-search>
                <el-select :disabled="!canEdit"
                           v-if="formData.byInvitePrize.type =='SURPRISE_BOX'"
                           v-model="formData.byInvitePrize.inviteCollection"
                           filterable>
                  <el-option v-for="item in boxList"
                             :label="item.name"
                             :value="item.id"
                             :key="item.id">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">#{{ item.id }}</span>
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

          </el-form-item>
          <!-- <el-form-item prop="byInvitePrize"
                        label="被邀者获得奖品"
                        style="width: calc(100vw - 450px)">

          </el-form-item> -->
          <el-form :model="{InvitePrize}"
                   ref="formlist"
                   label-position="right"
                   label-width="100px"
                   size="small">
            <el-form-item v-for="(item, index) in InvitePrize"
                          :label="'邀请获得' + (index+1)"
                          :key="item.key"
                          :prop="`InvitePrize[${index}].type`"
                          :rules="{
                        required: true, message: '礼品不能为空', trigger: 'change'
                        }">
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-select v-model="item.type"
                             clearable
                             filterable
                             :disabled="!canEdit"
                             @change="changeType(item)"
                             placeholder="请选择奖品类型">
                    <el-option v-for="item in typeOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6"
                        v-if="item.type != 'coupon'">
                  <el-form-item :prop="`InvitePrize[${index}].inviteCollection`"
                                :rules="{ required: true, message: '请选择藏品' }">
                    <collection-search :isEdit="isEdit"
                                       v-if="item.type =='asset'"
                                       v-model="item.inviteCollection"
                                       :projectId="formData.projectId"></collection-search>
                    <el-select v-if="item.type =='SURPRISE_BOX'"
                               v-model="item.inviteCollection"
                               :disabled="!canEdit"
                               filterable>
                      <el-option v-for="item in boxList"
                                 :label="item.name"
                                 :value="item.id"
                                 :key="item.id">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">#{{ item.id }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>

                </el-col>
                <el-col :span="6">
                  <el-input-number type="number"
                                   :disabled="!canEdit"
                                   placeholder="邀请数量"
                                   v-model="item.inviteNum"
                                   :min="1"></el-input-number>
                  <div class="tip">邀请多少人数达成奖励</div>
                </el-col>
                <el-col :span="3">

                  <el-button :disabled="!canEdit"
                             v-if="InvitePrize.length >=2"
                             @click.prevent="removeitem(item,InvitePrize)">删除</el-button>
                </el-col>
              </el-row>

            </el-form-item>
          </el-form>
          <el-form-item prop="type"
                        label="邀请人计算规则">
            <el-radio-group v-model="formData.inviterQualifications">
<!--              <el-radio :label="0"-->
<!--                        :disabled="!canEdit">注册</el-radio>-->
              <el-radio  :label="1"
                        :disabled="!canEdit">实名</el-radio>
<!--              <el-radio :label="2"-->
<!--                        :disabled="!canEdit">绑卡</el-radio>-->
              <!-- <el-radio label="4"
                        :disabled="!canEdit">持有藏品</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="holdCollectionIds"
                        label="持有指定藏品">
            <collection-search :isEdit="isEdit"
                               :isMultiple="true"
                               v-model="formData.holdCollectionIds"
                               :projectId="formData.projectId"></collection-search>
            <div class="tip">可不选可多选，只要持有其中一款即算完成</div>
          </el-form-item>
          <el-form-item prop="awardDistributionType"
                        label="奖品发放类型">
            <el-radio-group v-model="formData.awardDistributionType">
              <el-radio label="ZERO"
                        :disabled="!canEdit">每达到一个条件实时发放一次奖励</el-radio>
              <el-radio label="ONE"
                        :disabled="!canEdit">活动结束后发放所有已达成条件的奖励</el-radio>
              <el-radio label="TWO"
                        :disabled="!canEdit">活动结束后按最高奖励发放</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item class="form-submit">
            <el-button :disabled="!canEdit"
                       @click="additem(InvitePrize)">增加奖品</el-button>
            <el-button @click="onSave"
                       :loading="saving"
                       type="primary"> 保存 </el-button>
            <el-button @click="onDelete"
                       :disabled="saving"
                       type="danger"
                       v-if="formData.id">
              删除
            </el-button>
            <el-button @click="$router.go(-1)"
                       :disabled="saving">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import RichText from '../components/RichText.vue';
import SingleUpload from '../components/SingleUpload.vue';
export default {
    components: { RichText, SingleUpload },
    name: 'ActivityEdit',
    created() {
        this.getBoxList();
        this.$http
            .post(
                '/collection/all',
                {
                    size: 10000,
                    sort: 'sort,desc;createdAt,desc',
                    query: { del: false, source: 'OFFICIAL', type: 'DEFAULT' }
                },
                { body: 'json' }
            )
            .then(res => {
                this.options = res.content;
                this.filterOptions = this.options.filter(i => i.projectId == this.$store.state.projectId);
                this.filterOptions1 = this.options.filter(i => i.projectId == this.$store.state.projectId);
            });
        if (this.$route.query.id) {
            this.$http
                .get('activityInvite/get/' + this.$route.query.id)
                .then(res => {
                    console.log(res, 'res');
                    this.formData = res;
                    this.InvitePrize = res.invitePrizeList;
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
                awardDistributionType: 'ZERO',
                inviterQualifications: 1,
                holdCollectionIds: [],
                byInvitePrize: {
                    type: null,
                    inviteCollection: null
                }
            },
            isEdit: this.$route.query.id ? true : false,
            options: [],
            filterOptions: {},
            InvitePrize: [
                {
                    type: '',
                    inviteCollection: '',
                    inviteNum: 1
                }
            ],
            boxList: [],
            typeOptions: [
                { label: '藏品', value: 'asset' },
                { label: '惊喜盒子', value: 'SURPRISE_BOX' }
            ],
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                inviteStartdatetime: [
                    {
                        required: true,
                        message: '请输入活动开始时间',
                        trigger: 'change'
                    }
                ],
                inviteEnddatetime: [
                    {
                        required: true,
                        message: '请输入活动结束时间',
                        trigger: 'change'
                    }
                ],
                poster: [
                    {
                        required: true,
                        message: '请上传活动海报',
                        trigger: 'blur'
                    }
                ],
                prl: [
                    {
                        required: true,
                        message: '请上传活动背景',
                        trigger: 'blur'
                    }
                ],
                content: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length) {
                                callback();
                            } else {
                                callback(new Error('请上传详情'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                projectId: [{ required: true, message: '请选择项目' }]
            }
        };
    },
    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    methods: {
        changeType(row) {
            console.log(row, 'row');
            return (row.inviteCollection = '');
        },
        getBoxList() {
            const data = {
                page: 0,
                query: {
                    del: false,
                    projectId: this.formData.projectId,
                    createdAt: ''
                },
                size: 10000,
                sort: 'createdAt,desc'
            };
            this.$http
                .post('/activitySurpriseBox/all', data, { body: 'json' })
                .then(res => {
                    this.boxList = res.content;
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        removeitem(item, that) {
            var index = that.indexOf(item);
            if (index !== -1) {
                that.splice(index, 1);
            }
        },
        filterCollection(val) {
            this.filterOptions = this.options.filter(
                i => i.projectId == this.formData.projectId && (i => i.name.includes(val) || i.id == val)
            );
        },
        filterCollection1(val) {
            this.filterOptions1 = this.options.filter(
                i => i.projectId == this.formData.projectId && (i.name.includes(val) || i.id == val)
            );
        },
        // 保存验证
        onSave() {
            let list = [];
            list.push(this.submitForm('form'), this.submitForm('formlist'));
            Promise.all(list).then(() => {
                this.submit();
            });
        },
        // 表单集合校验
        submitForm(formName) {
            return new Promise((resolve, reject) => {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        resolve();
                    } else reject();
                });
            });
        },
        // onSave() {
        //     this.$refs.form.validate(valid => {
        //         if (valid) {
        //             this.submit();
        //         } else {
        //             return false;
        //         }
        //     });
        // },
        additem(that) {
            that.push({
                type: '',
                inviteCollection: '',
                inviteNum: 1
            });
        },
        submit() {
            let data = {
                ...this.formData,
                invitePrizeList: this.InvitePrize
            };

            this.saving = true;
            console.log(data, 'data');
            this.$http
                .post('/activityInvite/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityInvite/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    },
    watch: {
        'formData.projectId'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.getBoxList();
            }
        }
        // 'formData.byInvitePrize.type'(newVal, oldVal) {
        //     console.log(this.formData.byInvitePrize, 'inv', newVal, 'new', oldVal);

        //     if (newVal != oldVal) {
        //         this.formData.byInvitePrize.inviteCollection = '';
        //     }
        // }
    }
};
</script>
<style lang="less" scoped>
.tip {
    font-size: 12px;
    color: @text3;
    margin-top: 5px;
}
</style>
